// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-course-tsx": () => import("../src/templates/course.tsx" /* webpackChunkName: "component---src-templates-course-tsx" */),
  "component---src-templates-track-tsx": () => import("../src/templates/track.tsx" /* webpackChunkName: "component---src-templates-track-tsx" */),
  "component---src-templates-posts-tsx": () => import("../src/templates/posts.tsx" /* webpackChunkName: "component---src-templates-posts-tsx" */),
  "component---src-templates-post-tsx": () => import("../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-tags-tsx": () => import("../src/templates/tags.tsx" /* webpackChunkName: "component---src-templates-tags-tsx" */),
  "component---src-pages-404-tsx": () => import("../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-affiliates-tsx": () => import("../src/pages/affiliates.tsx" /* webpackChunkName: "component---src-pages-affiliates-tsx" */),
  "component---src-pages-authors-tsx": () => import("../src/pages/authors.tsx" /* webpackChunkName: "component---src-pages-authors-tsx" */),
  "component---src-pages-contact-tsx": () => import("../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faq-tsx": () => import("../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-join-authors-tsx": () => import("../src/pages/join-authors.tsx" /* webpackChunkName: "component---src-pages-join-authors-tsx" */),
  "component---src-pages-licencing-index-tsx": () => import("../src/pages/licencing/index.tsx" /* webpackChunkName: "component---src-pages-licencing-index-tsx" */),
  "component---src-pages-privacy-index-tsx": () => import("../src/pages/privacy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-index-tsx" */),
  "component---src-pages-pro-webinar-tsx": () => import("../src/pages/pro-webinar.tsx" /* webpackChunkName: "component---src-pages-pro-webinar-tsx" */),
  "component---src-pages-services-tsx": () => import("../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */)
}

